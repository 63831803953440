import Lang from 'lang.js';
import translations from '@/js/_translations';
import Config from '@/js/_config';

const __LAZY_TRANSLATIONS__ = true;

Lang.prototype._get = Lang.prototype.get;

const lang = new Lang({
  messages: translations,
  locale: Config.locale,
  fallback: Config.fallbackLocale,
});

lang.get = (key, replacements = {}, locale = lang.getLocale()) => {
  if (window.Tricon.adminShowSlugs) return key;
  const string = lang._get(key, replacements, locale);

  if (string === key && __LAZY_TRANSLATIONS__) {
    if (replacements.placeholder !== undefined) {
      return replacements.placeholder;
    }

    const keySplit = key.split('.');
    const label = keySplit[keySplit.length - 1];

    return lang._applyReplacements(label.charAt(0).toUpperCase() + label.slice(1).replace(/[_-]/g, ' '), replacements);
  }

  return string;
};

export default lang;
