import lodash from 'lodash';
import axios from 'axios';
import moment from 'moment';
import jQuery from 'jquery';
import config from '@/js/_config';

// Lodash
window._ = lodash;

// Config
window.config = config;

// Axios
window.axios = axios;
window.axios.defaults.headers.common = {
  'X-CSRF-TOKEN': window.Tricon.csrfToken,
  'X-Requested-With': 'XMLHttpRequest',
  'Content-Language': config.locale,
  Accept: 'application/json',
};

// Moment
moment.locale(config.locale);
window.moment = moment;

// jQuery
window.jQuery = jQuery.noConflict();
window.$ = window.jQuery;

// Bootstrap file input
(function ($) {
  $(document).on('input', '.custom-file input', function () {
    const files = [];
    for (let i = 0; i < $(this)[0].files.length; i++) {
      files.push($(this)[0].files[i].name);
    }
    $(this).next('.custom-file-label').html(files.join(', '));
  });
}(window.jQuery));
