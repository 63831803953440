<template>
  <iframe
      class="w-100"
      v-if="search"
      :height="height"
      style="border:0"
      loading="lazy"
      allowfullscreen
      referrerpolicy="no-referrer-when-downgrade"
      :src="mapsUrl">
  </iframe>
</template>

<script>
export default {
  name: 'google-map-embed',

  data() {
    return {
      apiKey: window.Tricon.googleMapsApiKey,
    };
  },

  props: {
    mode: {
      type: String,
      default: 'search',
    },
    zoom: {
      type: [Number, String],
      default: 15,
    },
    lat: {
      type: [Number, String],
      default: null,
    },
    lng: {
      type: [Number, String],
      default: null,
    },
    search: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: '400px',
    },
  },

  computed: {
    hasCoords() {
      return this.lat && this.lng;
    },

    mapsUrl() {
      const params = {
        key: this.apiKey,
        zoom: this.zoom,
      };

      if (this.mode === 'search') {
        params.q = this.search ?? '';
      } else if (this.mode === 'coords' && this.hasCoords) {
        params.q = `${this.lat},${this.lng}`;
      }

      return `https://www.google.com/maps/embed/v1/place?${new URLSearchParams(params).toString()}`;
    },
  },

  methods: {

  },
};
</script>
